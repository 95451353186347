//import {useState} from "react"
import {useBetween} from 'use-between'
import SharedAccess from "./Components/sharedAccess"
import Home from "./Components/home"
import About from "./Components/about"
import Browse from "./Components/browse"
import './App.css'

function App() {
  const {operator} = useBetween(SharedAccess);

  const Facebook = () =>
  {
    window.location.href="https://www.facebook.com/profile.php?id=100078858694618&mibextid=LQQJ4d"
  }

  const TikTok = () =>
  {
    window.location.href='https://www.tiktok.com/@woddy_wood_pile?_t=8f6uMpbZM22&_r=1'
  }

  const Email = () =>
  {
    window.location.href='mailto: wwoodys71@gmail.com'
  }

  return (
    <div className="App">
      <header className='bg-headerboard'>
        <text className='title'>Woody's Wood Pile</text>
      </header>

      <nav className='nav-bar'>
        <section className="sec-left">
          <img onClick={() => {Facebook()}} className='facebook-logo pntr' src='./facebook.png' alt=''/>
          <img onClick={() => {TikTok()}} className='tiktok-logo pntr' src='./tiktok.png' alt=''/>
          <img onClick={() => {Email()}} className='email-logo pntr' src='./email.png' alt=''/>
        </section>
        <section className="sec-right">
          <NavListener />
        </section>
      </nav>

      <br></br>
      <main>
        {operator === 0 ? <Home /> : operator === 1 ? <Browse /> : operator === 2 ? <About /> : <Home />}
      </main>
    </div>
  );
}

function NavListener()
{
  const {operator, setOperator} = useBetween(SharedAccess);

  if(operator === 0)
  {
    return(
      <>
        <text className="nav-btn pntr" onClick={() => {setOperator(1)}}>Browse</text>
        <text className="nav-btn pntr" onClick={() => {setOperator(2)}}>About</text>
      </>
    )
  }

  if(operator === 1)
  {
    return(
      <>
        <text className="nav-btn pntr" onClick={() => {setOperator(0)}}>Home</text>
        <text className="nav-btn pntr" onClick={() => {setOperator(2)}}>About</text>
      </>
    )
  }

  if(operator === 2)
  {
    return(
      <>
        <text className="nav-btn pntr" onClick={() => {setOperator(1)}}>Browse</text>
        <text className="nav-btn pntr" onClick={() => {setOperator(0)}}>Home</text>
      </>
    )
  }
  
}

export default App
