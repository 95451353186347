

const About = () =>
{
    return(
        <>
            <br></br>
            <section className="about-sec">
                <h2 className="wht">Transforming Nature's Finest into Your Wildest Imagination</h2>

                <br></br>
                <img className="logo" src="/saw.png" alt="" />
                <br></br>

                <h4 className="wht">Discover the World of Cool Wood Craft:</h4>

                <p className="wht">🌲 Handpicked Materials: Only the most exquisite and sustainable wood species.</p>
                <p className="wht">🛠️ Craftsmanship at Its Best: Meticulously curated designs that blend tradition with innovation.</p>
                <p className="wht">🎨 Your Vision, Our Creation: Customizable pieces that reflect your unique style and taste.</p>
                <p className="wht">✨ From Classic to Contemporary: Explore a diverse range of wood crafts to suit every aesthetic.</p>

                <br></br>
                <h3 className="wht">Why Choose Woody's Wood Pile?</h3>
                <br></br>

                <p className="wht">🔥 Passion for Wood: Our artisans live and breathe woodworking, infusing love and dedication into each creation.</p>
                <p className="wht">🌍 Sustainability Matters: We source our wood responsibly, ensuring minimal environmental impact.</p>
                <p className="wht">🤝 Crafted with Care: Every item is a testament to precision, durability, and the beauty of natural wood.</p>
                <p className="wht">🏆 Exceptional Quality: Our commitment to excellence results in heirloom pieces that stand the test of time.</p>

                <br></br>
                <h3 className="wht">"Crafting Dreams, One Piece of Wood at a Time"</h3>
                <br></br>
            </section>
        </>
    )
}

export default About