import {useState} from "react"

const SharedAccess = () => 
{
    const [operator, setOperator] = useState(0)

  return{
    operator, setOperator
  }
}

export default SharedAccess