//import {useState} from "react"

const Browse = () =>
{
    return(
        <>
            <br></br>
            <br></br>
            <section className="centered-display">
                <div className="nothing-card">
                    <i class="fas fa-eye-slash"></i>
                    <p>Nothing To See Yet...</p>
                </div>
            </section>
        </>
    )
}

export default Browse