import ReactPlayer from 'react-player'

const Home = () =>
{
    return(
        <>
            <h2 className="wht">
                Welcome to our Timber Haven - Where Craftsmanship Meets Creativity!
            </h2>
            <h4 className="wht">
                Your Vision, Our Creation: Customizable pieces that reflect your unique style and taste.
            </h4>

            <br></br>
            
            <img className="logo" src="/saw.png" alt="" />

            <br></br>
            <br></br>

            <section className="card-grid">
                <div className="grid-colomn pad-btm-10">
                    <img src="/display1.jpg" className="card-image rnd-10 marg-top-5" alt=""/>
                    <img src="/display2.jpg" className="card-image rnd-10 marg-top-5" alt=""/>
                    <img src="/display4.jpg" className="card-image rnd-10 marg-top-5" alt=""/>
                </div>
                <div className="grid-colomn">
                    <img src="/display3.jpg" className="card-image rnd-10 marg-top-3" alt=""/>
                </div>
            </section>

            <section className="under-grid-card">
                <div className="display-card">
                    <img src="/display5.jpg" className="card-image rnd-10" alt=""/>

                    <ReactPlayer
                        className='marg-top-10'
                        url='/product_video1.mp4'
                        width='100%'
                        height='auto'
                        playing={true}
                        loop={true}
                        muted={true}
                        playsInline={true}
                    />

                    <br></br>

                    <h1 className="wht">
                        Transforming Timber into Treasured Creations.
                    </h1>
                    <h1 className="wht">
                        WoodWorking Wonders, Crafted Just for You.
                    </h1>

                    <br></br>

                </div>
            </section>
            <br></br>
            
        </>
    )
}

export default Home